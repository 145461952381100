<template>
    <div class="content">
        <div class="comp-filter-bar clearfix">
            <a href="/qa/1318/t/0" class="moco-change-smalle-btn active">全部</a>
            <a href="/qa/1318/t/1" class="moco-change-smalle-btn">评论</a>
            <a href="/qa/1318/t/2" class="moco-change-smalle-btn">问答</a>
            <a href="/qa/1318/t/3" class="moco-change-smalle-btn">未解决</a>
            <a href="/qa/1318/t/4" class="moco-change-smalle-btn">精华</a>
            <span @click="show='评论'" style="float: right" class="js-pub-btn moco-btn moco-btn-gray-l">我要发布</span>
        </div>
        <div class="answertabcon">
            <div class="course_quescon">
                <div v-for="(item, index) in QuestionList" :key="index" class="wenda-listcon mdeListp clearfix" data-id="624511">
                    <div class="headslider qa-medias l">
                        <a href="/u/5863564/courses" class="media" target="_blank" title="慕田峪5389237">
                          <img src="https://tse1-mm.cn.bing.net/th/id/R-C.583e2458c88a95bbe9eb1aa33b78d6f1?rik=oE3oUc8dUCYlFA&riu=http%3a%2f%2ffile.qqtouxiang.com%2fnvsheng%2f2019-08-26%2f1b5a8611c58b6075796cb57a15b45b75.jpg&ehk=VURVFfIKSTHpXfwS0LZDheMx4iFgVS%2bpSdOnhDzb8cI%3d&risl=&pid=ImgRaw&r=0&sres=1&sresct=1" width="40" height="40"/>
                        </a>
                    </div>
                    <div class="wendaslider qa-content">
                        <div class="tit"><a href="/u/5863564/courses" target="_blank">{{ item.name }}</a></div>
                        <div class="cnt">{{ item.content }}</div>
                        <div class="replymegfooter qa-footer clearfix">
                            <div class="l-box l">
                                <a title="赞" href="javascript:;" class="js-pl-praise moco-btn moco-btn-gray-l" data-id="624511"><span class="icon-thumb-revert"></span><em>0</em></a>
                                <a href="/video/23630" class="from">1-1 课程引言</a>
                            </div>
                            <span class="r timeago">2021-12-30</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-show="false" class="moco-modal-overlay" style="z-index: 901; opacity: 1">
            <div class="moco-modal-layer" style="
          z-index: 901;
          visibility: visible;
          left: 514px;
          top: 93.5px;
          width: 460px;
          height: 168px;
        ">
                <div class="moco-modal-title">
                    <div><span>我要发布</span><a style="float: right" href="javascript:void(0)" class="js-modal-close">x</a></div>
                </div>
                <div class="moco-modal-inner">
                    <div class="moco-modal-dialog" style="display: block">
                        <div class="publish-box">
                            <a href="javascript:void(0)" class="moco-btn moco-btn-red moco-btn-lg js-comment-btn">我要评论</a>
                            <a href="javascript:void(0)" style="float: right" class="moco-btn moco-btn-red moco-btn-lg js-qa-btn">我有问题</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-show="show == '评论'" class="moco-modal-overlay" style="z-index: 904; opacity: 1">
            <div class="moco-modal-layer" style="
          z-index: 904;
          visibility: visible;
          left: 464px;
          top: 49px;
          width: 560px;
          height: 260px;
        ">
                <div class="moco-modal-title">
                    <div><span>我要评论</span><a href="javascript:void(0)" @click="show=false" style="float: right">x</a></div>
                </div>
                <div class="moco-modal-inner">
                    <div class="moco-modal-dialog" style="display: block">
                        <div id="js-pub-container" class="clearfix">
                            <div class="pub-input-box clearfix">
                                <div class="wgt-ipt-wrap pub-editor-wrap l" id="js-pl-input-fake">
                                   <textarea id="js-pl-textarea" class="" placeholder="扯淡、吐槽、表扬、鼓励……想说啥就说啥！" v-model="pltext"></textarea>
                                    <span style="float: right" class="num-limit"><span id="js-pl-limit">0</span>/300</span>
                                </div>
                            </div>
                            <div style="margin-top: 10px" id="pub-btm" class="pub-btm pub-comment clearfix">
                                <div class="captcha-verify-box js-verify-box hide"></div>
                                <input type="button" id="js-pl-submit" class="moco-btn moco-btn-red moco-btn-lg r" value="发表评论" @click="pltextP"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-show="show" class="moco-modal-blackout js-moco-modal-cancel" style="z-index: 900; opacity: 0.7; width: 1488px; height: 3457px"></div>
    </div>
</template>

<script>
    import api from "../../ulti/ajax";
    export default {
        methods: {
            // 提交评论
            pltextP() {
                console.log(this.pltext);
            },
        },
        async created() {
            let courseid = this.$route.query.id;
            this.QuestionList = (await api.getQuestionList(courseid)).data.rows;
            // console.log(QuestionList)
        },
        data() {
            return {
                QuestionList: [],
                pltext: "",
                show: "",
            };
        },
    };
</script>

<style>
    body.moco-modal-body {
        overflow: hidden;
    }

    .moco-modal-overlay {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        overflow: auto;
    }

    .moco-modal-blackout {
        position: fixed;
        top: 0;
        left: 0;
        background: rgba(7, 17, 27, 0.6);
    }

    .moco-modal-layer {
        position: absolute;
        background: #fff;
        box-shadow: 0 8px 16px 0 rgba(7, 17, 27, 0.2);
        border-radius: 12px;
    }

    .moco-modal-layer.fixed {
        top: 20px !important;
        margin-bottom: 20px !important;
    }

    .moco-modal-title {
        margin-bottom: -8px;
        padding: 24px 32px 0;
        line-height: 20px;
    }

    .moco-modal-title div {
        font-size: 16px;
        font-weight: bold;
        color: #1c1f21;
    }

    .moco-modal-draggable {
        cursor: move;
    }

    .moco-modal-title a.moco-modal-close {
        font-size: 24px;
        position: absolute;
        right: 32px;
        top: 24px;
        color: #b2b8bd;
        display: block;
        text-decoration: none;
    }

    .moco-modal-title a.moco-modal-close:hover {
        color: #1c1f21;
    }

    .moco-modal-inner .moco-modal-dialog {
        padding: 32px;
    }

    .moco-modal-inner .moco-modal-wrap {
        box-sizing: border-box;
        width: 320px;
        padding: 32px;
        line-height: 1;
    }

    .moco-modal-content {
        font-size: 16px;
        font-weight: bold;
        color: #1c1f21;
        line-height: 24px;
    }

    .moco-modal-info {
        padding-top: 8px;
        font-size: 12px;
        color: #545c63;
        line-height: 16px;
    }

    .moco-modal-info a {
        color: #1481b8;
        text-decoration: none;
    }

    .moco-modal-info a:hover {
        color: #0f618a;
    }

    .moco-modal-btns {
        padding-top: 32px;
        text-align: right;
    }

    .moco-modal-btns .moco-btn {
        margin-left: 12px;
        min-width: 80px;
    }

    .moco-tip-layer {
        position: absolute;
        z-index: 99999;
        font-size: 12px;
        line-height: 16px;
        color: #1c1f21;
        padding: 12px 16px 12px 12px;
        border-radius: 8px;
    }

    .moco-prompt-layer {
        font-size: 12px;
        line-height: 16px;
        color: #1c1f21;
        padding: 12px 16px 12px 12px;
        border-radius: 8px;
    }

    .pub-editor-wrap {
        width: 100%;
        position: relative;
        padding: 8px 10px;
        border: 1px solid #b7bbbf;
        background-color: #fff;
        box-sizing: border-box;
        border-radius: 2px;
    }

    .pub-editor-wrap textarea {
        width: 97%;
        min-height: 62px;
        padding: 8px;
        color: #555;
        font-size: 14px;
        resize: none;
        line-height: 18px;
        font-family: "Microsoft YaHei";
    }

    .moco-modal-layer .moco-icon-tick-revert,
    .moco-modal-layer .moco-icon-ques-revert,
    .moco-modal-layer .moco-icon-point-revert {
        float: left;
        margin-right: 4px;
        font-size: 16px;
    }

    .moco-modal-layer .moco-icon-tick-revert {
        color: #1fad4e;
    }

    .moco-modal-layer .moco-icon-ques-revert {
        color: #1c1f21;
    }

    .moco-modal-layer .moco-icon-point-revert {
        color: #f20d0d;
    }

    i {
        font-style: normal;
    }

    .mdeListp {
        position: relative;
        margin-bottom: 8px;
        padding: 32px;
        background: #fff;
        box-shadow: 0 4px 8px 0 rgba(7, 17, 27, 0.1);
        border-radius: 12px;
    }

    .mdeListp .icon-finish,
    .mdeListp .icon-wenda {
        position: absolute;
        left: 0;
        top: 0;
        width: 48px;
        height: 48px;
        background: url(/static/img/wenda/icon_wenda.png) no-repeat 0 0;
        background-size: cover;
    }

    .mdeListp .icon-finish {
        background-image: url(/static/img/wenda/icon_finish.png);
    }

    .mdeListp .qa-medias {
        width: 96px;
        overflow: hidden;
        text-align: center;
    }

    .mdeListp .qa-medias .meida:hover {
        color: #be3948;
        text-decoration: underline;
    }

    .mdeListp .qa-medias img {
        display: block;
        width: 40px;
        height: 40px;
        margin: 0 auto;
        border-radius: 50%;
        vertical-align: top;
    }

    .mdeListp .qa-medias .isfinish,
    .mdeListp .qa-medias .isflag,
    .mdeListp .qa-medias .issport,
    .mdeListp .qa-medias .nofinish {
        font-size: 24px;
        display: inline-block;
        position: relative;
        top: -20px;
        left: 14px;
        border: 1px solid #fff;
        border-radius: 50%;
        background: #fff;
    }

    .mdeListp .qa-medias .isfinish {
        color: #00b43c;
    }

    .mdeListp .qa-medias .nofinish {
        font-size: 22px;
        color: #b7bbbf;
        border: 3px solid #fff;
    }

    .mdeListp .qa-content {
        padding-right: 20px;
        min-height: 85px;
        position: relative;
        overflow: hidden;
        word-wrap: break-word;
        word-break: break-all;
    }

    .mdeListp .qa-header {
        font-size: 14px;
        line-height: 20px;
        color: #363d40;
        position: relative;
    }

    .mdeListp .qa-header .name {
        display: block;
        max-width: 100%;
        font-size: 14px;
        color: #07111b;
        line-height: 22px;
        white-space: nowrap;
        overflow: hidden;
        word-wrap: break-word;
        word-break: break-all;
        font-weight: 700;
    }

    .mdeListp .qa-header .name:hover {
        color: #4d555d;
    }

    .mdeListp .qa-header .qa-header-cnt .istop {
        color: #ff7e00;
        margin-right: 5px;
    }

    .mdeListp .qa-header .qa-tit {
        font-size: 16px;
        font-weight: 700;
        word-break: break-all;
        word-wrap: break-word;
        color: #07111b;
        line-height: 24px;
    }

    .mdeListp .qa-header .qa-tit:hover {
        color: #f01414;
    }

    .mdeListp .qa-header .add-integral {
        font-size: 12px;
        color: #ff7802;
        font-weight: 700;
    }

    .mdeListp .qa-header .isflag,
    .mdeListp .qa-header .issport {
        background: url(/static/img/add-qa.png) no-repeat 0 0;
    }

    .mdeListp .qa-header .isflag {
        background-position: 0 -30px;
    }

    .mdeListp .comment-content {
        font-size: 16px;
        line-height: 28px;
    }

    .mdeListp .qa-footer {
        margin-top: 12px;
        color: #93999f;
        font-size: 12px !important;
    }

    .mdeListp .qa-footer .l-box em {
        font-style: normal;
        display: inline-block;
    }

    .mdeListp .qa-footer .l-box a {
        color: #93999f;
        margin-right: 30px;
    }

    .mdeListp .qa-footer .l-box a:hover {
        color: #4d555d;
    }

    .mdeListp .qa-footer a:hover {
        color: #00b43c;
    }

    .mdeListp .qa-footer .static-count {
        font-size: 12px;
        color: #93999f;
    }

    .mdeListp .qa-footer .static-count .static-item {
        display: inline-block;
    }

    .mdeListp .qa-footer .static-count .static-item.answer {
        margin-right: 26px;
    }

    .comp-filter-bar {
        position: relative;
        margin-bottom: 30px;
    }

    .moco-change-smalle-btn {
        background: #f8fafc;
        margin-right: 12px !important;
    }

    .replycont {
        margin-top: 10px;
        position: relative;
    }

    .replycont .wait-answer {
        margin-left: 20px;
        padding: 0 8px;
        display: inline-block;
        font-size: 12px;
        color: #fff;
        background: #fa0;
        box-shadow: 0 4px 8px 0 rgba(255, 170, 0, 0.4);
        border-radius: 6px;
        line-height: 26px;
        position: relative;
    }

    .replycont .wait-answer:before {
        font-size: 30px;
        position: absolute;
        color: #fa0;
        left: -17px;
    }

    .replycont a {
        font-size: 12px;
        color: #71777d;
    }

    .replycont a:hover {
        color: #2b333b;
    }

    .replycont .replyicon {
        background: url(/static/img/wendanew.png) no-repeat 0 0;
        position: absolute;
        top: 5px;
        left: 3px;
        width: 14px;
        height: 14px;
        display: inline-block;
        vertical-align: middle;
    }

    .replydes {
        padding-left: 20px;
        border-left: 2px solid #d9dde1;
        color: #545c63;
        font-size: 12px;
        line-height: 20px;
        word-wrap: break-word;
        word-break: break-all;
        overflow: hidden;
    }

    .replydes.best {
        border-left: 2px solid rgba(31, 173, 78, 0.2);
    }

    .replydes .replysign {
        margin-bottom: 4px;
    }

    .replydes .adopt {
        color: #00b43c;
    }

    .replydes .adopt .nickname {
        color: #00b43c;
    }

    .replydes .adopt .nickname:hover {
        color: #00c850;
    }

    .replydes .icon-imooc {
        position: relative;
        top: 2px;
        font-size: 18px;
        line-height: 22px;
        color: #f01400;
    }

    .replydes .what-credit {
        padding-left: 12px;
    }

    .replydes .replyuserhead {
        border-radius: 10px;
        overflow: hidden;
        width: 20px;
        height: 20px;
        display: inline-block;
        position: absolute;
        top: 2px;
    }

    .moco-modal-draggable {
        cursor: move;
    }

    .moco-modal-title a.moco-modal-close {
        font-size: 24px;
        position: absolute;
        right: 32px;
        top: 24px;
        color: #b2b8bd;
        display: block;
        text-decoration: none;
    }

    .moco-modal-title a.moco-modal-close:hover {
        color: #1c1f21;
    }

    .moco-modal-inner .moco-modal-dialog {
        padding: 32px;
    }

    .moco-modal-inner .moco-modal-wrap {
        box-sizing: border-box;
        width: 320px;
        padding: 32px;
        line-height: 1;
    }

    .moco-modal-content {
        font-size: 16px;
        font-weight: bold;
        color: #1c1f21;
        line-height: 24px;
    }

    .moco-modal-info {
        padding-top: 8px;
        font-size: 12px;
        color: #545c63;
        line-height: 16px;
    }

    .moco-modal-info a {
        color: #1481b8;
        text-decoration: none;
    }

    .moco-modal-info a:hover {
        color: #0f618a;
    }
</style>
