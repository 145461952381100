<template>
    <div class="content">
        <div class="course-description course-wrap">{{info}}</div>
        <div class="course-chapters">
            <div v-for="(item, index) in list" :key="index" class="chapter course-wrap">
                <h3>{{item.name}}</h3>
                <div class="chapter-description">{{item.introd}}</div>
                <ul class="video">
                    <li v-for="(v, index) in item.coursevideos" :key="index">
                      <!--  <a :href="`/video?id=${v.id}`" class="J-media-item">-->
                        <a :href="`${v.videourl}`" class="J-media-item" target="_blank">
                            <i class="imv2-shipin3 type"></i>
                            <em class="type-text">视频：</em>
                            {{v.name}}
                            <button class="r moco-btn moco-btn-red preview-btn">
                                开始学习
                            </button>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
    import api from '../../ulti/ajax'

    export default {
        async created() {
            let courseid = this.$route.query.id;
            this.data = (await api.getcoursedetail(courseid)).data.data;
            console.log(this.list)
        },
        props: ['info', "list"],
        data() {
            return {
                data: {
                    "id": 1,
                    "name": "Spring 5实战开发及新特性精讲3",
                    "type": 2,
                    "price": 288.0,
                    "level": 2,
                    "imagesmall": "http://121.201.66.25/group1/M00/00/00/eclCGWItmamAC0VLAAAgGvRZcSI844.jpg",
                    "ishot": 1,
                    "categoryid": 4,
                    "recommend": 8,
                    "isgroup": 8,
                    "slogan": "视角更全面，梳理更透彻，再也不担心 Spring 项目的改造升级",
                    "viewpoint": "随着 Spring的迭代升级，基于Spring 5的项目开发和改造需求爆发式增长。本课程通过基于Spring MVC和Spring WebFlux的服务实例对比学习，带大家了解Spring 5的新特性和类库升级时应注意的事项，掌握响应式编程范式并能够根据业务需求进行选型和开发。",
                    "totalhour": 14,
                    "learnpeple": 100,
                    "score": 98.0,
                    "originalprice": 500.0,
                    "chaptercount": 12,
                    "lessoncount": 33,
                    "addbycd": "admin",
                    "addtime": "2022-03-06 12:31:20",
                    "editbycd": "admin",
                    "edittime": "2022-03-13 03:13:43",
                    "active": 1,
                    "chapterlist": [
                        {
                            "id": 1,
                            "name": "第一章",
                            "coursevideos": [
                                {
                                    "id": 2,
                                    "courseid": 1,
                                    "number": 1,
                                    "name": "第1节",
                                    "parentid": 1,
                                    "introd": "33",
                                    "addbycd": "admin",
                                    "addtime": "2022-03-10 06:20:11",
                                    "editbycd": "admin",
                                    "edittime": "2022-03-10 07:26:20"
                                }
                            ]
                        },
                        {
                            "id": 3,
                            "name": "第二章",
                            "coursevideos": [
                                {
                                    "id": 4,
                                    "courseid": 1,
                                    "number": 1,
                                    "name": "每1节",
                                    "parentid": 3,
                                    "freeis": 1,
                                    "addbycd": "admin",
                                    "addtime": "2022-03-10 07:24:47",
                                    "editbycd": "admin",
                                    "edittime": "2022-03-10 07:24:47",
                                    "active": 1
                                },
                                {
                                    "id": 5,
                                    "courseid": 1,
                                    "number": 2,
                                    "name": "第2节",
                                    "parentid": 3,
                                    "freeis": 1,
                                    "addbycd": "admin",
                                    "addtime": "2022-03-10 07:24:59",
                                    "editbycd": "admin",
                                    "edittime": "2022-03-10 07:24:59",
                                    "active": 1
                                },
                                {
                                    "id": 6,
                                    "courseid": 1,
                                    "number": 3,
                                    "name": "第3课",
                                    "parentid": 3,
                                    "freeis": 1,
                                    "addbycd": "admin",
                                    "addtime": "2022-03-14 03:26:22",
                                    "editbycd": "admin",
                                    "edittime": "2022-03-14 03:26:22",
                                    "active": 1
                                }
                            ]
                        }
                    ]
                },
                videoList: [],
                tatic: `简介：写好产品文档，是每个产品经理都要掌握的基本功。，但产品新人总会遇到各种问题，比如：
            不清楚文档的内容，不知如何下笔； 文档内容不规范，逻辑混乱；
            盲目套用模板，分不清使用场景，耗时且无用。
            本课程主要讲解PRD的组成及写作技巧，从理论到案例，帮助产品新人实现从0到1，学会用产品思维思考问题，输出敏捷的PRD文档，快速上手日常产品工作。
     `
            }
        }
    }
</script>

<style>

    .course-info-main .content {
        float: left;
        padding-top: 36px;
        width: 100%
    }

    .course-description {
        line-height: 28px;
        white-space: pre-line
    }

    .course-wrap {
        margin-bottom: 8px;
        padding: 24px 32px 32px;
        background: #fff;
        box-shadow: 0 8px 16px 0 rgba(7, 17, 27, .1);
        border-radius: 12px;

    }

    .course-description {
        line-height: 28px;
        white-space: pre-line
    }

    .course-chapters .chapter h3 {
        color: #1c1f21;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px
    }

    .course-chapters .chapter .chapter-description {
        margin-top: 2px;
        font-size: 12px;
        color: #545c63;
        line-height: 18px
    }

    .course-chapters .chapter .video {
        padding-top: 16px
    }

    .course-chapters .chapter .video li:first-child a button {
        position: relative;
        right: -12px
    }

    .course-chapters .chapter .video li {
        position: relative;
        padding-left: 12px;
        height: 48px;
        line-height: 48px;
        list-style: none
    }

    .course-chapters .chapter .video li:hover {
        background: rgba(242, 13, 13, .05);
        border-radius: 4px;
        color: #f20d0d
    }

    .course-chapters .chapter .video li:hover .laststudy {
        color: #f20d0d
    }

    .course-chapters .chapter .video li:last-child {
        border: none
    }

    .course-chapters .chapter .video li .ing {
        position: absolute;
        right: 12px;
        top: 50%;
        margin-top: -9px;
        font-size: 16px;
        color: #00b43c
    }

    .course-chapters .chapter .video li .done {
        position: absolute;
        right: 12px;
        top: 50%;
        margin-top: -9px;
        font-size: 16px;
        color: #00b43c
    }

    .course-chapters .chapter .video li .start {
        position: absolute;
        right: 12px;
        top: 50%;
        margin-top: -9px;
        font-size: 16px;
        color: #d9dde1
    }

    .course-chapters .chapter .video li a {
        color: #1c1f21;
        display: block;
        overflow: hidden;
        word-break: break-all;
        display: -webkit-box;
        display: -moz-box;
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
        font-size: 14px;
        line-height: 48px
    }

    .course-chapters .chapter .video li a .imv2-tick {
        color: #37f !important
    }

    .course-chapters .chapter .video li a .type {
        font-size: 16px;
        color: #1c1f21;
        line-height: 48px;
        margin-right: 8px;
        padding: 0;
    }

    .course-chapters .chapter .video li a .type-text {
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 48px;
        margin-right: 4px
    }

    .course-chapters .chapter .video li a .preview-btn {
        display: none;
        margin: 12px;
        height: 24px;
        padding: 0 12px;
        font-size: 12px
    }

    .course-chapters .chapter .video li a:hover {
        color: #f20d0d
    }

    .course-chapters .chapter .video li a:hover i {
        color: #f20d0d
    }

    .course-chapters .chapter .video li a:hover .preview-btn {
        display: block
    }

    .course-chapters .chapter .video li a.practise, .course-chapters .chapter .video li a.programme, .course-chapters .chapter .video li a.studyvideo {
        background: url(/static/img/learnicon.png?v3.1) no-repeat 0 -121px
    }

    .course-chapters .chapter .video li a.practise {
        background-position: 0 -157px
    }

    .course-chapters .chapter .video li a.programme {
        background-position: 0 -194px
    }

    .course-chapters .chapter em {
        float: right;
        font-size: 12px;
        cursor: default
    }

    .course-chapters .chapter .laststudy, .course-chapters .chapter .new {
        color: #93999f;
        font-size: 12px;
        position: absolute;
        right: 35px;
        top: 0
    }

    .course-chapters .chapter .state-expand, .course-chapters .chapter .state-open, .course-chapters .chapter .state-unopen {
        background-image: url(/static/img/learnicon.png?v3.1);
        background-repeat: no-repeat;
        width: 20px;
        height: 40px;
        display: inline-block;
        vertical-align: middle;
        margin-right: 8px
    }

    .course-chapters .chapter .state-expand {
        background-position: 0 -37px
    }

    .course-chapters .chapter .state-open {
        background-position: 0 -37px
    }

    .course-chapters .chapter .state-unopen {
        background-position: 0 -82px
    }

    .course-chapters .chapter.unlearn h3 {
        color: #b5b9bc;
        cursor: default
    }
</style>
