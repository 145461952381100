<template>
    <div class="content">
        <div class="evaluation-info">
            <div class="evaluation-title l">综合<br/>评分</div>
            <div class="evaluation-score l">8.07</div>
            <div class="star-box l">
                <i class="icon-star2 active"></i>
                <i class="icon-star2 active"></i>
                <i class="icon-star2 active"></i>
                <i class="icon-star2 active"></i>
                <i class="icon-star2 active"></i>
            </div>
            <ul>
                <li>内容实用<span>8.2</span></li>
                <li>简洁易懂<span>8.2</span></li>
                <li>逻辑清晰<span>7.8</span></li>
            </ul>
        </div>
        <div class="evaluation-list">
            <div v-for="(item, index) in CommentByCourseId" :key="index" class="evaluation evaluate" id="147775">
                <div class="evaluation-con">
                    <a href="#/u/276912/courses" class="img-box">
                        <img src="https://tse1-mm.cn.bing.net/th/id/R-C.583e2458c88a95bbe9eb1aa33b78d6f1?rik=oE3oUc8dUCYlFA&riu=http%3a%2f%2ffile.qqtouxiang.com%2fnvsheng%2f2019-08-26%2f1b5a8611c58b6075796cb57a15b45b75.jpg&ehk=VURVFfIKSTHpXfwS0LZDheMx4iFgVS%2bpSdOnhDzb8cI%3d&risl=&pid=ImgRaw&r=0&sres=1&sresct=1" alt="安浪创想"/>
                    </a>
                    <div class="content-box">
                        <div class="user-info clearfix">
                            <a href="#/u/276912/courses" class="username">{{item.name}}</a>
                            <div class="star-box">
                                <i class="icon-star2 active"></i>
                                <i class="icon-star2 active"></i>
                                <i class="icon-star2 active"></i>
                                <i class="icon-star2 active"></i>
                                <i class="icon-star2 active"></i>
                                <span>10分</span>
                            </div>
                        </div>
                        <!--user-info end-->
                        <p class="content">{{item.content}}</p>
                        <div class="info clearfix">
                            <div class="actions l">
                                <a title="赞" href="javascript:;" class="js-pl-praise moco-btn moco-btn-gray-l" data-id="147775"><span class="icon-thumb-revert"></span><em>0</em></a>
                            </div>
                            <span class="time r">时间：{{item.edittime}}</span>
                        </div>
                    </div>
                    <!--content end-->
                </div>
                <!--evaluation-con end-->
                <div class="do-reply">
                    <div class="textarea-box">
                        <textarea name="teacher-reply" class="teacher-do-reply" placeholder="写下你对评价的回复..."></textarea>
                        <span class="num-tip"><em>0</em>/300</span>
                    </div>
                    <!--textarea-box end-->
                    <div class="ctrl clearfix">
                        <p class="errtip"></p>
                        <div class="btn-box">
                            <div class="moco-btn cancel">取消</div>
                            <div class="moco-btn moco-btn-red sure" data-comment-id="147775">回复</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import api from '../../ulti/ajax'

    export default {
        async created() {
            let courseid = this.$route.query.id;
            let data = (await api.getCommentByCourseId(courseid));
            console.log(data.data)
            this.CommentByCourseId = (await api.getCommentByCourseId(courseid)).data.rows;
            console.log(this.CommentByCourseId)
        },
        data() {
            return {
                CommentByCourseId: []
            }
        }
    }
</script>

<style scoped>
    .evaluation-info {
        margin-bottom: 32px;
        padding: 24px 32px;
        background: #fff;
        box-shadow: 0 4px 8px 0 rgba(7, 17, 27, .1);
        border-radius: 12px;
        overflow: hidden
    }

    .evaluation-info .evaluation-title {
        color: #1c1f21;
        line-height: 16px;
        font-weight: 700
    }

    .evaluation-info .evaluation-score {
        padding-left: 16px;
        font-size: 32px;
        font-weight: 700;
        color: #4d555d;
        line-height: 32px
    }

    .evaluation-info .star-box {
        margin-top: 6px;
        padding-left: 10px
    }

    .evaluation-info ul {
        text-align: right
    }

    .evaluation-info ul li {
        padding-left: 48px;
        display: inline-block
    }

    .evaluation-info ul li span {
        padding-left: 10px;
        position: relative;
        top: 3px;
        font-size: 24px;
        color: #f90;
        line-height: 24px
    }

    .evaluate {
        margin-bottom: 8px;
        padding: 24px 32px;
        background: #fff;
        box-shadow: 0 4px 8px 0 rgba(7, 17, 27, .1);
        border-radius: 12px
    }

    .evaluate .errtip {
        line-height: 48px;
        color: #f01400;
        font-size: 12px;
        text-align: right
    }

    .evaluate .user-head {
        display: inline-block;
        width: 64px;
        overflow: hidden;
        text-align: left
    }

    .evaluate .user-head img {
        width: 48px;
        height: 48px;
        border-radius: 50%
    }

    .evaluate .satisfaction-degree {
        height: 24px;
        overflow: visible
    }

    .evaluate .satisfaction-degree h3 {
        float: left;
        margin-right: 15px;
        font-size: 14px;
        color: #14191e;
        text-align: left;
        line-height: 20px
    }

    .evaluate .satisfaction-degree .star-box {
        float: left
    }

    .evaluate .satisfaction-degree .star-box i {
        font-size: 20px
    }

    .evaluate .textarea-box {
        margin-left: 64px;
        position: relative;
        padding: 8px 10px;
        border: 1px solid rgba(51, 51, 51, .4);
        border-radius: 6px;
        background-color: #fff;
        box-sizing: border-box
    }

    .evaluate .textarea-box:focus {
        border-color: #93999f
    }

    .evaluate .textarea-box textarea {
        display: block;
        width: 100%;
        height: 82px;
        resize: none
    }

    .evaluate .textarea-box .num-tip {
        position: absolute;
        right: 10px;
        bottom: 6px;
        font-size: 12px;
        color: #b5b9bc;
        line-height: 20px
    }

    .evaluate .textarea-box .num-tip.err em {
        color: #f01400
    }

    .evaluate .ctrl-box {
        margin-left: 64px
    }

    .evaluate .evaluate-item {
        padding: 10px 0 15px
    }

    .evaluate .evaluate-item .evaluate-title {
        float: left;
        width: 64px;
        font-weight: 700;
        line-height: 22px
    }

    .evaluate .evaluate-item li {
        float: left;
        margin-right: 45px
    }

    .evaluate .evaluate-item li h3 {
        display: inline-block;
        height: 20px;
        line-height: 20px;
        font-size: 12px;
        color: #4d555d;
        text-align: left
    }

    .evaluate .evaluate-item li .star-box {
        display: inline-block;
        height: 20px
    }

    .evaluate-succ {
        display: none;
        height: 100px;
        background-color: #f6f6f6;
        color: #787d82;
        font-size: 14px;
        text-align: center;
        line-height: 100px
    }

    .evaluate-succ i {
        position: relative;
        top: 4px;
        color: #07b540;
        font-size: 20px;
        margin-right: 10px
    }

    .evaluate-succ em {
        color: #f90
    }

    .evaluation-info {
        margin-bottom: 32px;
        padding: 24px 32px;
        background: #fff;
        box-shadow: 0 4px 8px 0 rgba(7, 17, 27, .1);
        border-radius: 12px;
        overflow: hidden
    }

    .evaluation-info .evaluation-title {
        color: #1c1f21;
        line-height: 16px;
        font-weight: 700
    }

    .evaluation-info .evaluation-score {
        padding-left: 16px;
        font-size: 32px;
        font-weight: 700;
        color: #4d555d;
        line-height: 32px
    }

    .evaluation-info .star-box {
        margin-top: 6px;
        padding-left: 10px
    }

    .evaluation-info ul {
        text-align: right
    }

    .evaluation-info ul li {
        padding-left: 48px;
        display: inline-block
    }

    .evaluation-info ul li span {
        padding-left: 10px;
        position: relative;
        top: 3px;
        font-size: 24px;
        color: #f90;
        line-height: 24px
    }

    .evaluation-list {
        margin-top: 0;
        border-top: none;
        padding-top: 0
    }

    .evaluation-list .evaluation-con {
        position: relative;
        padding-left: 64px
    }

    .evaluation-list .evaluation-con .img-box {
        position: absolute;
        top: 0;
        left: 0;
        background-color: #f01400;
        width: 48px;
        height: 48px;
        border-radius: 50%;
        overflow: hidden
    }

    .evaluation-list .evaluation-con .img-box img {
        display: block;
        height: 100%
    }

    .evaluation-list .evaluation-con .content-box .user-info .username {
        float: left;
        margin-right: 10px;
        color: #787d82;
        font-size: 12px;
        text-align: left;
        line-height: 20px;
        font-weight: 700
    }

    .evaluation-list .evaluation-con .content-box .user-info .username:hover {
        color: #f01400
    }

    .evaluation-list .evaluation-con .content-box .user-info .star-box {
        float: right
    }

    .evaluation-list .evaluation-con .content-box .user-info .star-box span {
        font-size: 12px;
        position: relative;
        top: -2px;
        margin-left: 5px
    }

    .evaluation-list .evaluation-con .content-box .content {
        float: none;
        padding-top: 0;
        line-height: 28px;
        font-size: 14px;
        color: #14191e;
        text-align: left;
        margin-top: 8px;
        word-break: break-all
    }

    .evaluation-list .evaluation-con .content-box .info {
        margin-top: 10px;
        color: #b5b9bc;
        font-size: 12px;
        line-height: 20px
    }

    .evaluation-list .evaluation-con .content-box .info .show-reply-btn {
        margin-left: 10px;
        line-height: 36px;
        cursor: pointer
    }

    .evaluation-list .evaluation-con .content-box .info .post-action {
        float: left;
        display: block;
        padding-left: 16px;
        color: #b5bdc0;
        font-size: 14px;
        overflow: hidden;
        cursor: pointer
    }

    .evaluation-list .evaluation-con .content-box .info .post-action.on, .evaluation-list .evaluation-con .content-box .info .post-action:hover {
        text-decoration: none;
        color: #4d555d
    }

    .evaluation-list .evaluation-con .content-box .info .post-action .icon {
        cursor: pointer
    }

    .evaluation-list .evaluation-con .content-box .info .post-action em {
        padding: 0 3px 0;
        line-height: 16px
    }

    .evaluation-list .evaluation-con .content-box .info .post-action .icon-thumb-revert {
        position: relative;
        top: 2px;
        font-size: 16px
    }

    .evaluation-list .evaluation-con .content-box .info .praise-anim {
        -webkit-animation: praiseAnim .5s .1s;
        -moz-animation: praiseAnim .5s .1s;
        -o-animation: praiseAnim .5s .1s;
        animation: praiseAnim .5s .1s
    }

    @-webkit-keyframes praiseAnim {
        0%, 100% {
            opacity: 1;
            transform: scale(1)
        }

        50% {
            opacity: .3;
            transform: scale(1.4)
        }
    }

    @-moz-keyframes praiseAnim {
        0%, 100% {
            opacity: 1;
            transform: scale(1)
        }

        50% {
            opacity: .3;
            transform: scale(1.4)
        }
    }

    @keyframes praiseAnim {
        0%, 100% {
            opacity: 1;
            transform: scale(1)
        }

        50% {
            opacity: .3;
            transform: scale(1.4)
        }
    }

    .evaluation-list .do-reply {
        display: none;
        margin-top: 30px;
        margin-left: 64px
    }

    .evaluation-list .do-reply .textarea-box {
        position: relative;
        margin-left: 0
    }

    .evaluation-list .do-reply .textarea-box textarea {
        display: block;
        width: 100%;
        height: 62px;
        resize: none
    }

    .evaluation-list .do-reply .textarea-box .num-tip {
        position: absolute;
        right: 10px;
        bottom: 6px;
        font-size: 12px;
        color: #b5b9bc;
        line-height: 20px
    }

    .evaluation-list .do-reply .textarea-box .num-tip.err em {
        color: #f01400
    }

    .evaluation-list .do-reply .ctrl {
        margin-top: 20px
    }

    .evaluation-list .do-reply .ctrl p.errtip {
        float: left;
        color: #f01400;
        font-size: 14px;
        line-height: 30px;
        text-align: left
    }

    .evaluation-list .do-reply .ctrl .btn-box {
        float: right
    }

    .evaluation-list .teacher-reply {
        background-color: #f6f6f6;
        color: #787d82;
        padding: 20px;
        margin-top: 15px;
        word-break: break-all;
        border-radius: 6px;
        margin-left: 64px
    }

    .evaluation-list .teacher-reply span {
        color: #f01400
    }

    .star-box i {
        font-size: 16px;
        color: #d0d6d9
    }

    .star-box i.active {
        color: #f90
    }

    .star-box em {
        position: relative
    }

    .star-box i.icon-star_half {
        position: absolute;
        left: 0;
        top: 0;
        color: #f90
    }

    .star-box.js-star {
        cursor: pointer;
        position: relative;
        overflow: visible
    }

    .star-box .star-tip {
        display: none;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 10;
        width: 128px;
        padding: 10px;
        font-size: 14px;
        color: #f90;
        line-height: 20px;
        border: 1px solid #f90;
        background-color: #fff
    }

    .star-box .star-tip b {
        position: absolute;
        left: 50%;
        top: -6px;
        margin-left: -6px;
        width: 12px;
        height: 6px;
        font-size: 0;
        line-height: 0;
        overflow: hidden;
        background: url(/static/img/course/arrow.png) no-repeat left top
    }

</style>
