<template>
  <div id="main">
    <div class="mycourse-infos">
      <div class="w pr">
        <div class="path">
          <a href="#/">首页</a>
          <i class="icon-right2"></i>
        <!--  <a href="/list">免费课</a>-->
          <i class="icon-right2"></i>
          <a class="last-mark" href="javascript:;">{{ course.title }}</a>
        </div>
        <!-- 顶部主体信息 -->
        <div class="hd clearfix"><h2 class="l">{{ course.title }}</h2></div>
        <div class="statics clearfix">
          <div class="teacher-info l">
            <a href="#/" target="_blank">
              <img data-userid="9148836" class="js-usercard-dialog" :src="course.customer.avatar" width="80" height="80"/>
            </a>
            <span class="tit"><a href="#/" target="_blank">{{ course.customer.name }}</a><i class="icon-imooc"></i></span>
           <!-- <span class="job">{{ course.customer.zy }}</span>-->
          </div>
          <div class="static-item l"><span class="meta">难度</span><span class="meta-value">{{ course.levellable }}</span></div>
          <div class="static-item l"><span class="meta">时长</span><span class="meta-value"> {{ course.totalhour }}</span></div>
          <div class="static-item l"><span class="meta">学习人数</span><span class="meta-value js-learn-num">{{ course.learnpeple }}</span></div>

          <div class="static-item l score-btn">
            <span class="meta">综合评分</span>
            <span class="meta-value">{{ course.score }}</span>
            <div class="score-wrap triangle">
              <div class="score-box">
                <a href="#/" class="person-num"><span class="person-num l">{{ course.scorepeple }}人评价</span></a>
                <a href="#/" class="evaluation-btn r">查看评价</a>
                <div class="score-detail-box">
                  <div class="score-static-item"><span class="meta-value">{{ course.score1 }}</span><span class="meta">内容实用</span></div>
                  <div class="score-static-item"><span class="meta-value">{{ course.score2 }}</span><span class="meta">简洁易懂</span></div>
                  <div class="score-static-item"><span class="meta-value">{{ course.score3 }}</span><span class="meta">逻辑清晰</span></div>
                </div>
                <div class="icon-drop_down"></div>
              </div>
            </div>
          </div>
        </div>
        <!-- 顶部右侧分享 -->
        <!--<div class="extra">
          <div class="share-action r bdsharebuttonbox bdshare-button-style0-16">
            <div class="share js-follow-action"><i class="follow-action icon-star_outline"></i><span>收藏</span></div>
            <a href="javascript:;" class="share wx js-share icon-share-weichat" title="分享到微信"></a>
            <a href="javascript:;" class="share qq js-share icon-share-qq" title="分享到QQ空间"></a>
            <a href="javascript:;" class="share sina js-share icon-share-weibo" title="分享到新浪微博"></a>
          </div>
        </div>-->
      </div>
    </div>
    <div class="course-info-menu">
      <div class="w">
        <ul class="course-menu">
          <li><a class="moco-change-big-btn" :class="{ active: activeData == '章节' }" @click="activeData = '章节'" href="JavaScript:void(0)">章节</a></li><li>
            <span>12</span>
            <a class="moco-change-big-btn" :class="{ active: activeData == '问答' }" @click="activeData = '问答'" href="JavaScript:void(0)">问答</a>
          </li>
          <li><span>4</span><a class="moco-change-big-btn" :class="{ active: activeData == '评价' }" @click="activeData = '评价'" href="JavaScript:void(0)">评价</a></li>
        </ul>
      </div>
    </div>
    <div class="mainsss clearfix w">
      <div class="content-wrap clearfix">
        <canner1 :info="course.viewpoint" :list="course.chapterlist" v-show="activeData == '章节'" />
        <canner2 v-show="activeData == '问答'" />
        <canner3 v-show="activeData == '评价'" />


        <!-- 右侧开始学习 -->
        <div class="aside r">
          <div class="course-wrap course-aside-info js-usercard-box">
            <!--<div class="learn-btn"><a :href="`/video?id=${this.$route.query.id}`" class="moco-btn moco-btn-red moco-btn-lg J-learn-course">开始学习</a></div>-->
            <div class="learn-btn"><a  href="javascript:;"  @click="buy()" style="width: 150px" class="moco-btn moco-btn-red moco-btn-lg J-learn-course">立即购买</a></div>
            <br>
            <div class="learn-btn"><a  href="javascript:;"  @click="addCart()" style="width: 150px;background-color: #0dbbd0"  class="moco-btn moco-btn-red moco-btn-lg J-learn-course">加购物车</a></div>


            <!--  <div class="btns js-btns" data-pay="0">
                <a href="javascript:;" class="js-buy-trigger red-btn gobuy" id="buy-trigger" @click="buy()">立即购买</a>
                <a href="javascript:;" class="js-addcart addcart" @click="addCart">加购物车</a>
              </div>-->
            <div class="course-info-tip">
              <dl class="first"><dt>课程须知</dt><dd class="autowrap">{{ course.viewpoint }}</dd></dl>
              <dl>
                <dt>老师告诉你能学到什么？</dt>
                <dd class="autowrap"><li>(1)系统掌握规范的文档写作方法，提高日常工作效率；</li><li>(2)学会用产品思维思考，结合案例拆解文档；</li><li>(3)巩固并提升原型工具的使用；</li><li>(4)了解产品开发流程；</li></dd></dl>
            </div>
          </div>
          <!--<div class="js-commend-box">
            <div class="mb40 recom-course-list-box">
              <h4>推荐课程</h4>
              <ul class="js-recom-course moco-aside-course clearfix">
                <li>
                  <div class="aside-course-img"><img src="//climg.mukewang.com/60a239850909d94f06960344-280-160.png"/><p class="aside-course-type">体系课</p></div>
                  <div class="aside-course-content">
                    <a href="//class.imooc.com/sc/137?utm_source=courserightcoding" class="aside-course-name" target="_blank">新互联网人的产品经理课</a>
                    <p class="aside-course-price"><span class="aside-discount-price">￥1600.00</span></p>
                    <div class="aside-course-dot"><i class="imv2-dot_samll"></i></div>
                    <p class="aside-course-grade">16步骤/16门课</p>
                    <div class="aside-course-dot"><i class="imv2-dot_samll"></i></div>
                    <p class="aside-course-people"><i class="imv2-set-sns"></i><span>292</span></p>
                  </div>
                </li>
              </ul>
            </div>
          </div>-->
        </div>
      </div>
    </div>

    <login @tables="tables" @close="close" v-show="login" />
    <regit @tables="tables" @close="close" v-show="regit" />

  </div>
</template>

<script>
import canner1 from "../../components/learn/canner1.vue";
import canner2 from "../../components/learn/canner2.vue";
import canner3 from "../../components/learn/canner3.vue";

import login from "../../components/header/login.vue";
import regit from "../../components/header/regit.vue";
import { mapState } from "vuex";
import api from '../../ulti/ajax.js'
import {getUser, setUser, removeToken, setToken,getCar,addCar} from "../../ulti/auth";
export default {
  data() {
    return {
      login: false,
      regit: false,
      data:{},
      activeData: "章节",
      course:{},
      ordersteptoken:""

    };
  },

  components: {
    canner1,
    canner2,
    canner3, login,
    regit,
  },

  computed: {
    ...mapState(["cache"]),

  },

 async created(){
    let courseid = this.$route.query.id;
    this.course  = (await api.getcoursedetail(courseid)).data.data;

   //console.log("this.data:"+this.data)


     /*this.wz={
        title: this.data.name,
        nan: "最容易",
        shichang: "1小时",
        renshu: "150",
      },*/
    //console.log(this.data)

   document.title=''+this.course.name;
  },

  methods: {
    async buy() {
      if(!this.cache.islogin){
        this.flg(1);
      }else{

        let courseid = this.$route.query.id;
        window.location.href = '#/cartOder?courseid='+ courseid

       // await  api.AddCart(this.$route.query.id).then(res => {
      }
    },
    async addCart() {

      if(!this.cache.islogin){
         this.flg(1);
      }else{
        await  api.AddCart(this.$route.query.id).then(res => {
          if (res.data.code == 200) {
            this.$store.dispatch("add_cartcount");

            alert("加入成功："+res.data.msg);
          }else{
            alert("操作失败："+res.data.msg);
            //this.getCode();
          }
        });

      }
    },

    flg(a) {
      if (a == 1) {
        this.login = true;
        this.regit = false;
      } else {
        this.login = false;
        this.regit = true;
      }
    },

    tables() {
      if (this.login) {
        this.login = false;
        this.regit = true;
      } else {
        this.login = true;
        this.regit = false;
      }
    },

    close() {
      this.regit = false;
      this.login = false;
    },

  },

};
</script>

<style scoped>
.w {
  max-width: 1152px;
  margin: 0 auto;
}
.pr {
  position: relative;
}
#main {
  padding-top: 0;
  color: #1c1f21;
  background: #f8fafc;
}
.mycourse-infos {
  position: relative;
  background-color: #172a34;
}
.mycourse-infos .w {
  height: 200px;
}
.mycourse-infos .path {
  position: relative;
  z-index: 1;
  padding-top: 16px;
  padding-bottom: 24px;
  line-height: 24px;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.5);
  opacity: 1;
}

.mycourse-infos .path span {
  color: rgba(255, 255, 255, 0.5);
}

.mycourse-infos .path a {
  color: rgba(255, 255, 255, 0.5);
}

.mycourse-infos .path a:hover {
  color: #fff;
}

.mycourse-infos .path .last-mark:hover {
  color: #a3aaae;
}

.mycourse-infos .hd {
  clear: both;
}

.mycourse-infos h2 {
  position: relative;
  z-index: 1;
  margin-bottom: 8px;
  line-height: 48px;
  font-size: 32px;
  color: #fff;
}

.mycourse-infos .video-desc-trigger {
  margin: 12px 0 0 28px;
  color: #fff;
  font-size: 14px;
  opacity: 0.6;
  transition: opacity 0.3s;
}

.mycourse-infos .video-desc-trigger:hover {
  opacity: 1;
}

.mycourse-infos .video-desc-trigger .ic-video {
  display: inline-block;
  margin-right: 4px;
  width: 16px;
  height: 16px;
  vertical-align: -3px;
  background: url(/static/img/course/v2/info_s.png) no-repeat 0 0;
}

.mycourse-infos .statics {
  color: #fff;
  font-size: 0;
  position: relative;
  z-index: 3;
  margin-right: 320px;
}

.mycourse-infos .statics .teacher-info {
  position: relative;
  padding-left: 56px;
  padding-right: 60px;
  line-height: 12px;
  font-size: 12px;
}

.mycourse-infos .statics .teacher-info img {
  float: left;
  margin-left: -56px;
  width: 48px;
  height: 48px;
  border-radius: 100%;
}

.mycourse-infos .statics .teacher-info .tit {
  display: block;
  margin-top: 3px;
}

.mycourse-infos .statics .teacher-info .tit a {
  font-size: 14px;
  line-height: 22px;
  font-weight: 700;
  color: #fff;
}

.mycourse-infos .statics .teacher-info .tit a:hover {
  color: #fff;
}

.mycourse-infos .statics .teacher-info .job {
  font-size: 12px;
  line-height: 18px;
}

.mycourse-infos .statics .teacher-info .icon-imooc {
  color: #f01400;
  font-size: 16px;
  margin: 0 0 0 8px;
  vertical-align: -2px;
}

.mycourse-infos .statics .teacher-info .teacher-course {
  display: none;
  position: absolute;
  left: 0;
  top: 70px;
  padding: 32px;
  padding-bottom: 16px;
  background: #fff;
  box-shadow: 0 8px 16px 0 rgba(7, 17, 27, 0.2);
  border-radius: 12px;
}

.mycourse-infos .statics .teacher-info .teacher-course .teacher-course-arrow {
  position: absolute;
  width: 100%;
  top: -21px;
  height: 35px;
  left: 0;
}

.mycourse-infos
  .statics
  .teacher-info
  .teacher-course
  .teacher-course-arrow
  span {
  width: 0;
  height: 0;
  border-width: 0 10px 10px;
  border-style: solid;
  border-color: transparent transparent #fff;
  overflow: hidden;
  top: 12px;
  left: 12px;
  position: absolute;
}

.mycourse-infos .statics .teacher-info .teacher-course .teacher-course-title {
  font-weight: 700;
  font-size: 16px;
  color: #07111b;
  line-height: 24px;
}

.mycourse-infos .statics .teacher-info .teacher-course .teacher-course-title a {
  font-weight: 200;
  font-size: 12px;
  color: #545c63;
  line-height: 24px;
}

.mycourse-infos .statics .teacher-info .teacher-course .recom-course-list li {
  float: left;
  width: 352px;
  height: 80px;
  overflow: hidden;
}

.mycourse-infos
  .statics
  .teacher-info
  .teacher-course
  .recom-course-list
  li
  .course-img {
  width: 120px;
  height: 80px;
}

.mycourse-infos .statics .teacher-info .teacher-course .teacher-course-big {
  width: 1056px;
}

.mycourse-infos .statics .teacher-info .teacher-course .teacher-course-price {
  margin-top: 2px;
  font-weight: 700;
  font-size: 12px;
  color: #4d555d;
  line-height: 20px;
}

.mycourse-infos
  .statics
  .teacher-info
  .teacher-course
  .teacher-course-price
  .sales-tip {
  padding: 0 4px;
  background: rgba(240, 20, 20, 0.6);
  border-radius: 2px;
  font-size: 12px;
  color: #fff;
  line-height: 20px;
  height: 20px;
}

.mycourse-infos
  .statics
  .teacher-info
  .teacher-course
  .teacher-course-price
  .sales-tip
  .sales-timer {
  display: inline;
  font-size: 12px;
  margin-right: 0;
  color: #fff;
  line-height: 20px;
  font-style: normal;
  float: none;
  margin-left: 4px;
}

.mycourse-infos
  .statics
  .teacher-info
  .teacher-course
  .teacher-course-price
  .discount-price {
  font-size: 12px;
  color: #f01414;
  margin-right: 4px;
  margin-top: 0;
}

.mycourse-infos .statics .teacher-info:hover .teacher-course {
  display: block;
}

.mycourse-infos .statics .static-item:last-child:after {
  display: none;
}

.mycourse-infos .statics .static-item {
  display: inline-block;
  position: relative;
  margin-right: 16px;
  font-size: 12px;
  top: 15px;
}

.mycourse-infos .statics .static-item:after {
  margin-left: 16px;
  content: "·";
}

.mycourse-infos .statics .static-item .meta {
  padding-right: 8px;
  line-height: 12px;
  font-size: 12px;
  font-weight: 700;
}

.mycourse-infos .statics .static-item .score-posi {
  position: absolute;
  padding: 0 8px;
  right: -160px;
  bottom: -6px;
  color: #fff;
  font-size: 12px;
  background: #fa0;
  box-shadow: 0 4px 8px 0 rgba(255, 170, 0, 0.4);
  border-radius: 6px;
  line-height: 26px;
}

.mycourse-infos .statics .static-item .score-posi .imv2-arrow1_l {
  font-size: 35px;
  left: -20px;
  position: absolute;
  top: -5px;
  color: #fa0;
}

.mycourse-infos .statics .static-item .score-wrap {
  display: none;
  position: absolute;
  top: -120px;
  left: 0;
  color: #fff;
  z-index: 1;
}

.mycourse-infos .statics .static-item .score-wrap .score-box {
  width: 192px;
  padding: 16px 20px;
  background: #fff;
  color: #545c63;
  box-shadow: 0 8px 16px 0 rgba(7, 17, 27, 0.2);
  border-radius: 8px;
}

.mycourse-infos .statics .static-item .score-wrap .score-box .person-num {
  font-size: 14px;
  color: #545c63;
}

.mycourse-infos .statics .static-item .score-wrap .score-box .evaluation-btn {
  font-size: 12px;
  color: #fa0;
  cursor: pointer;
}

.mycourse-infos .statics .static-item .score-wrap .score-box .score-detail-box {
  margin-top: 30px;
  clear: both;
}

.mycourse-infos
  .statics
  .static-item
  .score-wrap
  .score-box
  .score-detail-box
  .score-static-item {
  position: relative;
  display: inline-block;
  padding-right: 20px;
}

.mycourse-infos
  .statics
  .static-item
  .score-wrap
  .score-box
  .score-detail-box
  .score-static-item:last-child {
  padding: 0;
}

.mycourse-infos
  .statics
  .static-item
  .score-wrap
  .score-box
  .score-detail-box
  .score-static-item
  .meta-value {
  color: #1c1f21;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  opacity: 1;
}

.mycourse-infos
  .statics
  .static-item
  .score-wrap
  .score-box
  .score-detail-box
  .score-static-item
  .meta {
  margin-top: 10px;
  padding-right: 0;
  font-size: 12px;
  color: #545c63;
  font-weight: 200;
}

.mycourse-infos
  .statics
  .static-item
  .score-wrap
  .score-box
  .score-detail-box
  .score-static-item
  span {
  display: block;
  margin-top: 0;
}

.mycourse-infos .statics .static-item .score-wrap .score-box .icon-drop_down {
  width: 100%;
  position: absolute;
  font-size: 50px;
  top: 84px;
  color: #fff;
}

.mycourse-infos .statics .score-btn {
  cursor: pointer;
}

.mycourse-infos .statics .score-btn:hover .score-wrap {
  display: block;
}

.mycourse-infos .statics .static-time {
  font-size: 12px;
}

.mycourse-infos .extra {
  position: absolute;
  right: 0;
  top: 24px;
  z-index: 1;
}

.mycourse-infos .extra .share {
  display: inline-block;
  float: none;
  cursor: pointer;
  padding: 0;
  line-height: normal;
  margin: 0 12px;
  width: auto;
  height: auto;
  vertical-align: middle;
  background-image: none;
  font-size: 24px;
  background-repeat: no-repeat;
  color: rgba(255, 255, 255, 0.6);
}

.mycourse-infos .extra .share span {
  margin-top: 2px;
  margin-left: 5px;
  font-size: 12px;
  line-height: 24px;
  float: right;
}

.mycourse-infos .extra .share:hover {
  color: #fff;
}

.mycourse-infos .info-bg {
  overflow: hidden;
  z-index: 0;
}

.mycourse-infos .info-bg,
.mycourse-infos .info-bg .cover-canvas,
.mycourse-infos .info-bg .cover-img-wrap,
.mycourse-infos .info-bg .cover-mask {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.mycourse-infos .info-bg .cover-img-wrap {
  z-index: 0;
  overflow: hidden;
}

.mycourse-infos .info-bg .cover-img-wrap img {
  position: absolute;
  left: 0;
  top: 0;
}

.course-info-menu {
  height: 68px;
  line-height: 68px;
  background: #fff;
  box-shadow: 0 4px 8px 0 rgba(28, 31, 33, 0.1);
}

.course-info-menu .w {
  position: relative;
}

.course-info-menu .course-menu li {
  float: left;
  text-align: center;
  position: relative;
  margin-right: 80px;
}

.course-info-menu .course-menu li span {
  position: absolute;
  font-size: 12px;
  font-weight: 200;
  color: #9199a1;
  line-height: 18px;
  top: 10px;
  left: 36px;
}

.course-info-menu .course-menu a {
  padding: 0;
}

.comp-filter-bar {
  position: relative;
  margin-bottom: 30px;
}

.comp-filter-bar span.moco-btn {
  position: absolute;
  right: 0;
  top: -7px;
}

.moco-change-smalle-btn {
  background: #f8fafc;
  margin-right: 12px !important;
}

.course-wrap {
  margin-bottom: 8px;
  padding: 24px 32px 32px;
  background: #fff;
  box-shadow: 0 8px 16px 0 rgba(7, 17, 27, 0.1);
  border-radius: 12px;
}

.course-aside-info {
  margin-bottom: 32px;
  min-height: 400px;
  position: relative;
  z-index: 901;
}

.course-aside-info .close-guide-btn {
  position: absolute;
  bottom: -60px;
  left: 50%;
  transform: translateX(-50%);
  color: #fff;
  width: 104px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  border: 1px solid #fff;
  border-radius: 18px;
  cursor: pointer;
  box-sizing: border-box;
}

.share-guide-modal {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 900;
}

.mainsss {
  margin-bottom: 40px;
}

.mainsss .content-wrap {
  padding-right: 352px;
  margin-bottom: 29px;
  overflow: visible;
}

.mainsss .info-bar {
  position: relative;
  margin-bottom: 65px;
}

.mainsss .info-bar .start-study-btn {
  width: 320px;
  margin-bottom: -60px;
}

.mainsss .info-bar .start-study-title {
  position: absolute;
  right: 0;
  top: 70px;
  width: 240px;
  text-align: center;
  color: #787d82;
  font-size: 12px;
  height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.mainsss .info-bar.multiple .start-study-btn {
  margin-bottom: 0;
}

.mainsss .aside {
  margin-top: -148px;
  margin-right: -352px;
  width: 320px;
  position: relative;
  z-index: 2;
}

.mainsss .aside .learn-info {
  text-align: right;
  font-size: 12px;
  line-height: 18px;
}

.mainsss .aside .learn-info span {
  float: left;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
}

.mainsss .aside .learn-info-media {
  margin-bottom: 12px;
  font-size: 12px;
  color: #545c63;
  line-height: 18px;
  word-break: break-all;
}

.mainsss .aside .course-info-tip {
  padding-top: 24px;
}

.mainsss .aside .course-info-tip dl.first {
  margin-bottom: 24px;
}

.mainsss .aside .course-info-tip dt {
  margin-bottom: 6px;
  font-weight: 700;
  font-size: 14px;
}

.mainsss .aside .course-info-tip dd {
  font-size: 12px;
  line-height: 24px;
  color: #545c63;
  white-space: pre-line;
}

.mainsss .aside .progress {
  margin: 9px 0 24px;
  width: 100%;
  height: 16px;
  background: rgba(28, 31, 33, 0.1);
  border-radius: 8px;
}

.mainsss .aside .progress ins {
  height: 16px;
  content: "";
  width: 0%;
  transition: 2s width;
  background: #f20d0d;
  border-radius: 8px;
}

.mainsss .aside .buy a,
.mainsss .aside .learn-btn a {
  display: block;
}

.mainsss .aside .price-content {
  text-align: center;
  font-size: 0;
}

.mainsss .aside .price-content .group-box {
  position: relative;
}

.mainsss .aside .price-content .group-box .interval p {
  font-size: 16px;
  line-height: 22px;
  color: #1c1f21;
  text-align: center;
  font-weight: 700;
}

.mainsss .aside .price-content .group-box .interval p span {
  font-size: 16px;
  color: #f20d0d;
  text-align: center;
  line-height: 22px;
}

.mainsss .aside .price-content .group-box .group-list {
  margin-top: 16px;
  text-align: center;
}

.mainsss .aside .price-content .group-box .group-list .group-item {
  width: 32px;
  height: 32px;
  margin: 0 6px;
  background-color: #d8d8d8;
  border: 1px solid #eff1f2;
  border-radius: 50%;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.mainsss .aside .price-content .group-box .group-btn {
  width: 100%;
  border-radius: 24px;
  overflow: hidden;
  margin-top: 16px;
}

.mainsss .aside .price-content .group-box .group-btn li {
  float: left;
  width: 50%;
  box-sizing: border-box;
  background: #f20d0d;
  font-size: 16px;
  color: #fff;
  height: 48px;
  cursor: pointer;
}

.mainsss .aside .price-content .group-box .group-btn li:first-child {
  background-color: #f58e3d;
}

.mainsss .aside .price-content .group-box .group-btn li p {
  text-align: center;
  line-height: 48px;
}

.mainsss .aside .price-content .group-box .group-tip {
  font-size: 12px;
  color: #545c63;
  line-height: 12px;
  margin-top: 16px;
  text-align: center;
  font-weight: 700;
}

.mainsss .aside .price-content .group-box #share-group {
  display: none;
  position: absolute;
  bottom: -256px;
  left: -20px;
  z-index: 1000;
}

.mainsss .aside .price-content .group-box #share-group:after {
  display: block;
  content: "";
  position: absolute;
  top: -16px;
  left: 189px;
  width: 0;
  height: 0;
  border-left: 16px dashed transparent;
  border-right: 16px dashed transparent;
  border-bottom: 16px solid #fff;
}

.mainsss .aside .price-content .group-box #share-group .share-group-wrap {
  padding: 32px 60px;
  background: #fff;
  box-shadow: 0 0 16px 0 rgba(7, 17, 27, 0.2);
  border-radius: 18px;
}

.mainsss .aside .price-content .group-box #share-group .share-group-wrap p {
  font-size: 14px;
  color: #545c63;
  text-align: center;
  line-height: 20px;
  font-weight: 700;
  width: 180px;
}

.mainsss
  .aside
  .price-content
  .group-box
  #share-group
  .share-group-wrap
  .share-group {
  width: 140px;
  height: 140px;
  margin: 24px auto 0;
}

.mainsss
  .aside
  .price-content
  .group-box
  #share-group
  .share-group-wrap
  .share-group
  .loading {
  width: 36px;
  height: 36px;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url(/static/img/common/loading.gif) no-repeat;
  background-size: 100%;
  background-position: center;
}

.mainsss .aside .price-content .group-box .title {
  font-weight: 700;
  margin-bottom: 24px;
  text-align: center;
  font-size: 16px;
  line-height: 22px;
  color: #f20d0d;
}

.mainsss .aside .price-content .group-box .group-price {
  width: 100%;
  border-radius: 24px;
  overflow: hidden;
}

.mainsss .aside .price-content .group-box .group-price li {
  float: left;
  width: 50%;
  box-sizing: border-box;
  background: #f20d0d;
  font-size: 14px;
  color: #fff;
  height: 48px;
  line-height: 24px;
  cursor: pointer;
}

.mainsss .aside .price-content .group-box .group-price li:first-child {
  background-color: #f58e3d;
}

.mainsss .aside .price-content .group-box .group-price li p {
  text-align: center;
  line-height: 20px;
}

.mainsss .aside .price-content .group-box .group-price li p:first-child {
  margin-top: 4px;
}

.mainsss .aside .price-content .group-box .show-ori-price {
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  color: #aaa;
  text-decoration: line-through;
  margin-top: 20px;
}

.mainsss .aside .price-content .discount-box .discount-title {
  font-size: 16px;
  color: #1c1f21;
  font-weight: 700;
}

.mainsss .aside .price-content .discount-box .discount-time {
  font-size: 16px;
  color: #f20d0d;
  text-align: right;
  margin-left: 8px;
  font-weight: 700;
}

.mainsss .aside .price-content .price-box {
  margin-top: 8px;
  vertical-align: bottom;
}

.mainsss .aside .price-content .price-box .dis-price {
  font-size: 32px;
  color: #f01414;
  line-height: 45px;
  font-weight: 700;
}

.mainsss .aside .price-content .price-box .ori-price {
  font-size: 16px;
  color: #93999f;
  line-height: 32px;
  margin-left: 8px;
  font-weight: 700;
  text-decoration: line-through;
}

.mainsss .aside .price-content .price-box .cur-price {
  font-size: 32px;
  color: #1c1f21;
  font-weight: 700;
}

.mainsss .aside .price-content .price-box-notime {
  padding-top: 16px;
  box-sizing: border-box;
}

.mainsss .aside .price-content .price-box-notime .dis-price-notime {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
}

.mainsss .aside .price-content .price-box-notime .dis-price-notime .text {
  font-size: 32px;
  color: #f01414;
  line-height: 45px;
  font-weight: 700;
  margin-left: 20px;
}

.mainsss
  .aside
  .price-content
  .price-box-notime
  .dis-price-notime
  .discount-title-notime {
  font-size: 12px;
  color: #fff;
  line-height: 12px;
  font-weight: 700;
  background: rgba(242, 13, 13, 0.6);
  border-radius: 2px;
  padding: 3px 4px;
  margin-left: 12px;
  display: inline-block;
}

.mainsss .aside .price-content .price-box-notime .ori-price-notime {
  font-size: 16px;
  color: #93999f;
  line-height: 26px;
  font-weight: 700;
  text-decoration: line-through;
  text-align: left;
  padding-left: 26px;
}

.mainsss .aside .price-content .buy {
  margin-top: 22px;
}

.mainsss .aside .hd {
  margin-bottom: 38px;
}

.mainsss .aside h4 {
  font-weight: 700;
  font-size: 14px;
}

.mainsss .aside h4 small {
  margin-left: 4px;
  font-size: 12px;
  font-weight: 400;
}

.mainsss .aside .refresh {
  margin-top: 4px;
  font-size: 12px;
  color: #787d82;
}

.mainsss .aside .refresh:hover {
  color: #f01400;
}

.mainsss .course-brief {
  font-size: 14px;
  color: #4d555d;
  line-height: 28px;
  word-break: break-all;
  padding-left: 24px;
  padding-right: 24px;
}

.mainsss.has-progress .aside {
  padding-top: 14px;
}

.mainsss .content {
  float: left;
  padding-top: 36px;
  width: 100%;
}

.mainsss .content .ctit {
  margin-bottom: 6px;
  font-weight: 700;
  font-size: 18px;
  color: #f01400;
}

.mainsss .content .course-brief {
  font-size: 14px;
  color: #4d555d;
  line-height: 28px;
  word-break: break-all;
}

.mainsss .content .course-outline .bar {
  margin-bottom: 39px;
}

.mainsss .content .course-outline .tools {
  margin: 7px 0 0 18px;
}

.mainsss .content .course-outline .tools li {
  display: block;
  padding: 0 32px 0 20px;
  height: 20px;
  line-height: 20px;
  font-size: 16px;
  font-weight: 700;
  color: #787d82;
  border-left: 1px solid #d0d6d9;
  cursor: default;
}
.mainsss .content .course-outline .tools li i {
  margin-left: 8px;
  font-weight: 400;
  vertical-align: -2px;
}

.mainsss .content .course-outline .tools li span {
  display: none;
  margin-left: 8px;
  font-size: 12px;
}
.mainsss .content .course-outline .tools li:hover span {
  display: inline;
}
.mainsss .content .course-outline .tools li:hover i {
  display: none;
}
</style>
